"use client"

import { PHONE_REGEX } from "@constants/common";
import moment from "moment";

//check string is null or empty
export const isNullOrEmpty = (str: string) => {
    return !str || str.trim() === '';
}

//check string is not a email format
export const isNotEmail = (str: string) => {
    return !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(str);
}

//check string is a email format
export const isEmail = (str: string) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(str);
}

export function a11yProps(name: string, index: number) {
    return {
        id: `${name}-tab-${index}`,
        'aria-controls': `${name}-tabpanel-${index}`,
        sx: {
            p: "0px 4.5%",
            "&.Mui-selected": {
                color: '#49454F',
                backgroundColor: "#FEF7FF",
                fontWeight: '900',
                borderColor: "#000 !important"
            },
        }
    };
}

export function childProps(name: string, index: number) {
    return {
        id: `${name}-tab-${index}`,
        'aria-controls': `${name}-tabpanel-${index}`,
        sx: {
            padding: "10px 3%",
            color: '#49454F',
            fontWeight: '400',
            "&.Mui-selected": {
                color: '#49454F',
                fontWeight: '900',
            },
        }
    };
}

export const redirectToHome = () => {
    if (typeof window !== 'undefined' && location) {
        location.href = "/"
    }
};

export const IfBlankReturnNull = (str: string | null | undefined) => {
    return str?.trim().length === 0 ? null : str;
}

export function trimToMaxLength(input: string, maxLength: number) {
    // Check if the input length exceeds the maximum length
    if (input && input.length > maxLength) {
        // Remove the beginning characters to fit the max length
        return input.slice(input.length - maxLength);
    }
    return input;
}

export const formatPhone = (input: string) => {
    if (input) {
        // Remove any non-digit characters
        input = input.replace(/\D/g, '');
        // Format the string as 000-000-0000
        if (input.length > 3 && input.length <= 6) {
            return input.replace(/(\d{3})/, '$1-');
        }
        if (input.length > 6 && input.length < 10) {
            return input.replace(/(\d{3})(\d{3})/, '$1-$2-');
        }
        input = trimToMaxLength(input, 10)
        return input.replace(PHONE_REGEX, '$1-$2-$3');
    }
    return "";
}

export function timeDifferenceString(date1: moment.Moment, date2: moment.Moment): string {
    const diffInMinutes = date2.diff(date1, 'minutes');
    let result = '';
    if (diffInMinutes < 1) {
        return 'now';
    }
    if (diffInMinutes < 60) {
        // Show minutes if less than 1 hour
        result = `${diffInMinutes}m`;
    } else if (diffInMinutes < 1440) { // 1440 minutes = 24 hours
        // Show hours if less than 24 hours
        const diffInHours = date2.diff(date1, 'hours');
        result = `${diffInHours}hr`;
    } else if (diffInMinutes < 10080) { // 10080 minutes = 7 days
        // Show days if less than 7 days
        const diffInDays = date2.diff(date1, 'days');
        result = `${diffInDays}d`;
    } else {
        // Show weeks if 7 days or more
        const diffInWeeks = date2.diff(date1, 'weeks');
        result = `${diffInWeeks}wk`;
    }

    return result;
}
