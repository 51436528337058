import { FormControl, Grid, Select, MenuItem, Typography } from "@mui/material";
import React, { memo } from "react";
import { horizontalFormControlGridLeftStyle, horizontalFormControlGridRightStyle, menuItemStyle, requiredLabelStyle, selectMenuPropStyle } from "@styles/global-css-class";
import { FieldError } from "@components/FieldError";
import { IDropdownFormControlProperty } from "@definitions/index";
import { StyledFormLabel } from "@components/Common";
function DropdownFormControl({
  label,
  name,
  value,
  defaultValue,
  onChange,
  isRequired,
  isDisabled,
  isHidden,
  showSelect,
  listValues,
  placeHolder,
  formState,
  zIndex,
  xsFormControl = 12,
  mdFormControl = 12,
  xsLabel = 6,
  xsInput,
  textAlign,
  textErrorAlign,
  itemSx,
  textSx,
  ...props
}: Readonly<IDropdownFormControlProperty>) {
  return <Grid container item xs={xsFormControl} md={mdFormControl} data-sentry-element="Grid" data-sentry-component="DropdownFormControl" data-sentry-source-file="DropdownFormControl.tsx">
		<FormControl fullWidth margin='dense' {...props} data-sentry-element="FormControl" data-sentry-source-file="DropdownFormControl.tsx">
			<Grid container data-sentry-element="Grid" data-sentry-source-file="DropdownFormControl.tsx">
				<Grid item xs={xsLabel} sx={itemSx ?? horizontalFormControlGridLeftStyle} data-sentry-element="Grid" data-sentry-source-file="DropdownFormControl.tsx">
					<StyledFormLabel aria-describedby={name} hidden={isHidden} error={formState?.errors[name] !== undefined} sx={textSx ?? {
            ...requiredLabelStyle,
            textAlign: textAlign ?? "right"
          }} data-sentry-element="StyledFormLabel" data-sentry-source-file="DropdownFormControl.tsx">
						{label}
						{isRequired && <Typography style={{
              color: "red",
              fontSize: "18px"
            }}>
								*
							</Typography>}
					</StyledFormLabel>
				</Grid>
				<Grid item xs={xsInput ?? (xsLabel === 12 ? 12 : 12 - xsLabel)} sx={itemSx ?? horizontalFormControlGridRightStyle} data-sentry-element="Grid" data-sentry-source-file="DropdownFormControl.tsx">
					<Select name={name} hidden={isHidden} value={value ?? defaultValue ?? ''} error={formState?.errors[name] !== undefined} onChange={e => {
            onChange(e.target.value);
          }} size='small' sx={{
            width: "100%",
            "& .MuiSelect-select .notranslate::after": {
              content: `"Select ${placeHolder ?? ""}"`,
              opacity: 0.42
            }
          }} disabled={isDisabled} MenuProps={selectMenuPropStyle(zIndex ?? 99999)} displayEmpty data-sentry-element="Select" data-sentry-source-file="DropdownFormControl.tsx">
						{showSelect && <MenuItem sx={menuItemStyle} key={`${name}-9999-null`}>
								Select
							</MenuItem>}
						{listValues?.map((option, idx) => {
              idx += 1;
              return typeof option === "object" ? <MenuItem sx={menuItemStyle} key={`${name}-${idx}-${option.value}`} value={option.value}>
								{option.name}
							</MenuItem> : <MenuItem sx={menuItemStyle} key={`${name}-${idx}-${option}`} value={option}>
								{option}
							</MenuItem>;
            })}
					</Select>
				</Grid>
				{formState && <FieldError formState={formState} name={name} textAlign={textErrorAlign} />}
			</Grid>
		</FormControl>
	</Grid>;
}
export default memo(DropdownFormControl);