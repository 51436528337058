export const ISO_DATE_FORMAT = "YYYY-MM-DD"
export const SERVER_DATE_FORMAT = "YYYY-MM-DD HH:mm:ss"
export const DATE_FORMAT = "MM/DD/YYYY"
export const DEFAULT_DATE = "0001-01-01"
export const DRAFT_POSTFIX = "(draft)"
export const NUMBER_VALID_KEYS = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"]
export const DOT = "."
export const DASH = "-"
export const NAVIGATION_KEYS = ["ArrowDown", "ArrowLeft", "ArrowRight", "ArrowUp", "End", "Home", "PageDown", "PageUp"]
export const EDITING_KEYS = ["Backspace", "Clear", "Copy", "CrSel", "Cut", "Delete", "EraseEof", "ExSel", "ExSel", "Paste", "Redo", "Undo"]
export const TRUE_ARRAY = ["1", "TRUE", "true", true];
export const ALPHABETS = 'abcdefghijklmnopqrstuvwxyz'.toUpperCase();
export const PHONE_FORMAT = "000-000-0000"
export const PHONE_REGEX: RegExp = /(\d{3})(\d{3})(\d{4})/
export const PHONE_REGEX_DASH: RegExp = /\d{3}-\d{3}-\d{4}/
export const DEFAULT_CURRENCY = "USD"
export const DEFAULT_CURRENCY_FORMAT = "$"
export const DEFAULT_LOCALE = 'en-US'
export const CONFIRM_LABEL = 'Confirm'
export const VERIFY_LABEL = 'Verify'

// label
export const STATE_PROFILE_LABEL = "State Profile"
export const STATE_PROVINCE_LABEL = "State/Province"
export const STATE_PROVINCE_SPACE_LABEL = "State / Province"
export const STATE_REGION_LABEL = "State/Region"
export const PROFILE_NAME_LABEL = "Profile Name"
export const PROFILE_LABEL = "Profile"
export const RETAIL_TYPE_LABEL = "Retail Type"
export const GENRE_LABEL = "Genre"
export const COUNTRY_LABEL = "Country"
export const FEIN_LABEL = "FEIN (Federal Employer ID Number)"
export const SIC_LABEL = "SIC (Standard Industry Code)"
export const ALIAS_LABEL = "Alias"
export const ENTITY_CODE_LABEL = "Entity Code"
export const ENTITY_NAME_LABEL = "Entity Name"
export const NEW_PROFILE_TITLE = "New Profile"
export const COPY_AS_NEW_PROFILE_TITLE = "Copy As New"
export const ADD_CLIENT_TITLE = "Add Client"
export const EDIT_CLIENT_TITLE = "Edit Client"
export const CLIENT_CODE_LABEL = "Client Code"
export const CLIENT_NAME_LABEL = "Client Name"
export const DESCRIPTION_LABEL = "Description"
export const CURRENCY_CODES_LABEL = "Currency Codes"
export const CURRENCY_LABEL = "Currency"
export const ADDRESS_LABEL = "Address"
export const ADDRESS_2_LABEL = "Address 2"
export const ADDRESS_3_LABEL = "Address 3"
export const CITY_LABEL = "City"
export const STATE_LABEL = "State"
export const ZIP_LABEL = "Zip/Postal Code"
export const FIRST_NAME_LABEL = "First Name"
export const LAST_NAME_LABEL = "Last Name"
export const PHONE_LABEL = "Phone"
export const EMAIL_LABEL = "Email Address"
export const COMPANY_TYPE_LABEL = "Company Type"
export const FAX_LABEL = "Fax"
export const TITLE_LABEL = "Title"
export const NAME_LABEL = "Name"
export const FILING_TAX_ID_EXT_LABEL = "Filing Tax Id Ext"
export const FILING_TAX_ID_LABEL = "Filing Tax Id"
export const ISSUER_TYPE_LABEL = "Issuer Type"
export const PROGRAM_LABEL = "Program"
export const CLIENT_LABEL = "Client"
export const ISSUER_LABEL = "Issuer"
export const PROCESSOR_LABEL = "Processor"
export const RETAILER_LABEL = "Retailer"
export const NOTICES_LABEL = "Notices"
export const FILINGS_LABEL = "Filing"
export const DOES_CARD_COMPLIANT_DELIVER_NOTICES_LABEL = "Does Card Compliant Deliver Notices"
export const DOES_CARD_COMPLIANT_RECEIVE_RESPONSES_LABEL = "Does Card Compliant Receive Responses"
export const INHERITED_FROM_CLIENT_TOOLTIP = "Inherited from the client"
export const SAVE_LABEL = "Save"
export const OK_LABEL = "OK"
export const SAVE_DRAFT_LABEL = "Save Draft"
export const CREATE_DRAFT_LABEL = "Create Draft"
export const CANCEL_LABEL = "Cancel"
export const DELETE_LABEL = "Delete"
export const PUBLISH_LABEL = "Publish"
export const CREATED_DATE_LABEL = "Created Date"
export const ACTION_LABEL = "Action"
export const CREATE_AS_NEW_LABEL = "Create New Profile"
export const EDIT_ORGANIZATION_ENTITY_LABEL = "Edit Organization Entity"
export const GENERAL_LABEL = "General"
export const BILLING_LABEL = "Billing"
export const OPTI_EARNINGS_LABEL = "OptiEarnings"
export const ISSUING_LABEL = "Issuing"
export const ETL_LABEL = "ETL"
export const RISKS_PREFERENCES_LABEL = "Risks & Preferences"
export const PREFERENCES_LABEL = "Preferences"
export const ORGANIZATION_LABEL = "Organization"
export const NOTICE_INFORMATION_LABEL = "Notice Information"
export const BILLING_CONTACT_LABEL = "Billing Contact"
export const TECHNICAL_CONTACT_LABEL = "Technical Contact"
export const FIRST_CONTACT_LABEL = "1st Contact (Primary)"
export const SECONDARY_CONTACT_LABEL = "2nd Contact (Secondary)"
export const BUSINESS_ADDRESS_LABEL = "Business Address"
export const LEAD_ISSUER_LABEL = "Lead Issuer"
export const OTHER_ISSUER_LABEL = "Other Issuer"
export const LEAD_ISSUER_TYPE_LABEL = "Lead Issuer Type"
export const OTHER_ISSUER_TYPE_LABEL = "Other Issuer Type"
export const CLIENT_NUMBER_LABEL = "Client Number"
export const DATA_LAYOUT_LABEL = "Data Layout"
export const DUPLICATE_RECORDS_LABEL = "Duplicate Records"
export const ACCOUNT_ID_LABEL = "Account UUID"
export const ESCHEATABLE_DUE_DATE_LABEL = "Escheatable Due Date"
export const VERIFY_ACCOUNT_LABEL = "Verify Account"
export const SIMULATE_LABEL = "Simulate"
export const RUN_LABEL = "Run"

//Routes
export const ROUTE = {
    DATA_STREAMS: "/master-data/data-streams",
    DATA_LAYOUTS: "/master-data/data-layouts",
    FILINGS_NOTICES: "/filings-notices",
    REIMBURSEMENTS: "/filings-notices/reimbursements",
    NOTIFICATIONS: "/notifications",
    REVIEWS_APPROVALS: "/reviews-approvals",
}

// Title
export const APPLICATION_TITLE = "Card Compliant"
export const UN_CHANGE_TITLE = "Unsaved Changes"
export const DELETE_DRAFT_TITLE = "Delete Draft"
export const SAVE_AS_NEW_DRAFT_TITLE = "Create Draft"
export const DELETE_SIMULATIONS_TITLE = "Delete Simulation"
