import { IDataLayoutItem } from "./dataLayoutDefinitions";
import { defaultAutoCompleteProperties, defaultDropDownProperties, defaultStringProperties, IPropertyType } from "./definitions";

interface IDataStreamItem {
    id: number;
    dataLayoutId: number;
    sourceSystemId: number;
    clientCode: string | null;
    clientName: string | null;
    createdBy: string;
    processorCode: string | null;
    programCode: string | null;
    processorName: string | null;
    dataStreamName: string;
    dataLayoutName: string;
    brandCode: string;
    silverTransactionDeduplication: boolean;
    fileNameMappings: string;
    fileNamePattern: string;
    filePath: string;

    dataLayout: IDataLayoutItem | null;
    sourceSystem: ISourceSystemItem | null;
}

export interface ISourceSystemItem {
    id: number;
    description: string;
    ingestionType: string;
    rootPath: string;
    connectionSecret: string;
    connectionKey: string;
    active: boolean | null;
}


export const dataStreamResponsiveProps = {
    xsFormControl: 6,
    mdFormControl: 6,
}

export const dataStreamStringProperties: IPropertyType = {
    ...defaultStringProperties,
    ...dataStreamResponsiveProps,
}

export const dataStreamDropDownProperties: IPropertyType = {
    ...defaultDropDownProperties,
    ...dataStreamResponsiveProps,
}

export const dataStreamAutoCompleteProperties: IPropertyType = {
    ...defaultAutoCompleteProperties,
    ...dataStreamResponsiveProps,
}

export const FILE_FORMATS = {
    DELIMITED: 1,
    DSR: 2,
    EMAF: 3,
    ENCRYPTEDDELIMITED: 4,
    EXCEL: 5,
    FIXED_WIDTH: 6,
    XML: 7,
};

export type { IDataStreamItem }