interface ICategory {
    id: number;
    name: string;
    description: string;
    order: number;
    pbiEntities: ISimplePBIEntity[];
    createBy: string;
    createDate?: Date;
    lastModifyBy: string;
    lastModifyDate?: Date;
};
export interface IReportVisual {
    id: number;
    workspaceId: string;
    reportId: string;
    pageName: string;
    visualName: string;
    visualTitle: string;
    key: string;
    datasetIDs?: ISimpleDatasetIDs[];
};

interface IPowerBIEntity {
    id: number;
    name: string;
    fileName: string;
    description: string;
    workspaceId: string;
    entityIDs: IEntityIDs[];
    datasetIDs: IDatasetIDs[];
    categories: ICategory[];
    createBy: string;
    createDate?: Date;
    lastModifyBy: string;
    lastModifyDate?: Date;
};

interface IEntityIDs {
    id: number;
    entityType: string;
    entityID: string;
    createBy: string;
    createDate?: Date;
    lastModifyBy: string;
    lastModifyDate?: Date;
    name: string;
};

interface IDatasetIDs {
    id: number;
    datasetID: string;
    createBy: string;
    createDate?: Date;
    lastModifyBy: string;
    lastModifyDate?: Date;
    name: string;
    rlsRole?: string;
};

interface ISimplePBIEntity {
    id: number;
    name: string;
    fileName: string;
    workspaceId: string;
    reportId: string;
    entityIDs: ISimpleEntityIDs[];
    datasetIDs: ISimpleDatasetIDs[];
};

interface ISimpleEntityIDs {
    id: number;
    entityType: string;
    entityID: string;
    name: string;
};

interface ISimpleDatasetIDs {
    id: number;
    datasetID: string;
    name: string;
    rlsRole?: string;
};


export interface IRlsRole {
    datasetId: string;
    role: string;
};

interface IUpdatePowerBIEntity {
    id: number;
    name: string;
    description: string;
    workspaceId: string;
    categoryIds: number[];
};

enum PBIEntityType {
    ReportLibrary = 0,
    Dashboard = 1,
}

enum ReportEntityType {
    Report = "Report",
    PaginatedReport = "PaginatedReport",
}

interface IPowerBIReport {
    id: number;
    reportId: string;
    name: string;
    reportName: string;
    description: string;
    workspaceId: string;
    categories: ICategory[];
};

export { PBIEntityType, ReportEntityType };

export type { IUpdatePowerBIEntity, ISimpleDatasetIDs, ISimpleEntityIDs, ISimplePBIEntity, IDatasetIDs, IEntityIDs, IPowerBIEntity, ICategory, IPowerBIReport }