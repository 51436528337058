export const ReportParams: any = {
	type: "report",
	cssClassName: "dashboard-embed-container",
	isFilterVisible: true,
	isPageNavigationVisible: false,
};

export const PaginatedReportSettingsParams: any = {
	commands: {
		parameterPanel: {
			enabled: false,
			expanded: false,
		},
	},
};

export enum ReportName {
	Dashboard = "Dashboard",
	Escheatment = "Escheatment",
	RevenueRecognition = "Revenue Recognition",
	Forecasting = "Forecasting",
	MyDashboard = "MyDashboard",
};