export interface IReimbursement {
    reimbursementActivityId: number;
    state: string;
    clientCode: string;
    activityAmountAfterFiling: number;
    transactionsAfterFiling: number;
    numOfCardsAfterFiling: number;
    currentAmount: number;
    isExceedThreshold: boolean;
    reimbursementStatusName: string;
    reimbursementStatus: number;
    lastFiledDate: string | null;
    naupaReimbursementPdfPath: string | null;
    naupaReimbursementSupplementalCsvPath: string | null;
    filingDateRange: string;
}

export enum ReimbursementStatus {
    Eligible = 1,
    Started = 2,
    DocumentsReady = 3,
    Pending = 4,
    Rejected = 5,
    Accepted = 6,
    FundsTransferPending = 7,
    FundsReceived = 8,
    Complete = 9,
    DocumentGenerationFailed = 999,
}

export enum ReimbursementModalAction {
    Begin = 1,
    Approve = 2,
    Complete = 3,
    FundsReceived = 4,
    SingleRowAction = 5,
}

export interface IReimbursementStatusInfo {
    ids: number[];
    status: ReimbursementStatus;
}
