import { Grid, Typography } from "@mui/material";
import { flexCenterProps, titleContextProps } from "@styles/global-css-class";
import { memo } from "react";
const TitleForm = ({
  title
}: {
  title: string;
}) => {
  return <Grid container item sx={{
    ...flexCenterProps,
    pb: 1,
    px: "8px"
  }} data-sentry-element="Grid" data-sentry-component="TitleForm" data-sentry-source-file="TitleForm.tsx">
			<Typography sx={titleContextProps} data-sentry-element="Typography" data-sentry-source-file="TitleForm.tsx">{title}</Typography>
		</Grid>;
};
export default memo(TitleForm);