import { Grid, Box, FormControl } from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { memo, useEffect, useState } from "react";
import { IFormControlProperty } from "@definitions/index";
import { FieldError } from "@components/FieldError";
import { paddingX1Props } from "@styles/global-css-class";
function NewDatePickerFormControl({
  label,
  name,
  value,
  defaultValue,
  onChange,
  isRequired,
  isDisabled,
  isHidden,
  formState,
  xsFormControl = 12,
  mdFormControl = 12,
  xsLabel = 6,
  xsInput,
  textAlign,
  textErrorAlign,
  itemSx,
  textSx,
  zIndex,
  ...props
}: Readonly<IFormControlProperty>) {
  const [dateValue, setDateValue] = useState<Dayjs | null>(null);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [cleared, setCleared] = useState<boolean>(false);
  const handleValue = (dateValue: Dayjs | null) => {
    if (dateValue === null || dateValue.toString().startsWith('0001-01-01')) {
      return defaultValue ?? null;
    } else {
      return dateValue;
    }
  };
  useEffect(() => {
    if (value !== null && value !== undefined && !value.toString().startsWith('0001-01-01')) {
      setDateValue(dayjs(value));
    } else {
      setDateValue(null);
    }
  }, [value]);
  useEffect(() => {
    if (cleared) {
      const timeout = setTimeout(() => {
        setCleared(false);
      }, 300);
      return () => clearTimeout(timeout);
    }
    return () => {};
  }, [cleared]);
  return <Grid container item xs={xsFormControl} md={mdFormControl} sx={itemSx ?? {
    ...paddingX1Props,
    alignContent: 'flex-start'
  }} data-sentry-element="Grid" data-sentry-component="NewDatePickerFormControl" data-sentry-source-file="NewDatePickerFormControl.tsx">
		<FormControl fullWidth margin='normal' required={isRequired} error={formState?.errors[name] !== undefined} {...props} data-sentry-element="FormControl" data-sentry-source-file="NewDatePickerFormControl.tsx">
			<LocalizationProvider dateAdapter={AdapterDayjs} data-sentry-element="LocalizationProvider" data-sentry-source-file="NewDatePickerFormControl.tsx">
				<DemoContainer components={["DatePicker"]} sx={{
          width: "100%"
        }} data-sentry-element="DemoContainer" data-sentry-source-file="NewDatePickerFormControl.tsx">
					<Box onFocus={() => setIsFocused(true)} onBlur={() => setIsFocused(false)} sx={{
            width: "100%"
          }} data-sentry-element="Box" data-sentry-source-file="NewDatePickerFormControl.tsx">
						<DatePicker name={name} label={label} value={handleValue(dateValue)} onChange={newValue => {
              if (newValue?.isValid()) {
                onChange(newValue.format("YYYY-MM-DD"));
              } else {
                onChange(newValue?.toString());
              }
            }} slotProps={{
              field: {
                clearable: true,
                onClear: () => setCleared(true)
              },
              textField: {
                required: isRequired,
                error: formState?.errors[name] !== undefined,
                autoFocus: isRequired && formState?.errors && Object.keys(formState.errors)[0] === name
              },
              popper: {
                sx: {
                  zIndex: zIndex ?? 99999
                }
              }
            }} disabled={isDisabled} sx={{
              width: "100%"
            }} data-sentry-element="DatePicker" data-sentry-source-file="NewDatePickerFormControl.tsx" />
					</Box>
				</DemoContainer>
			</LocalizationProvider>
			{formState && <FieldError formState={formState} name={name} textAlign={textErrorAlign} />}
		</FormControl>
	</Grid>;
}
;
export default memo(NewDatePickerFormControl);