import { ICountryItem } from "./countryDefinitions";
import { IClientItem, IIssuerItem, IProcessorItem } from "./organizationDefinitions";
import { IStandardIndustryItem } from "./standardIndustryDefinitions";
import { IStateItem } from "./stateDefinitions";

interface IEntityItem {
    id: number;
    code: string;
    name: string;
    alias: string;
    state: IStateItem | null;
    country: ICountryItem | null;
    federalEmployerIDNumber: string;
    standardIndustry: IStandardIndustryItem;
    client: IClientItem | null;
    issuer: IIssuerItem | null;
    processor: IProcessorItem | null;
    createBy: string,
    createDate: Date,
};

interface IUpsertEntityItem {
    id: number;
    code: string;
    name: string;
    alias: string;
    stateId: number;
    countryId: number;
    federalEmployerIDNumber: string | null;
    standardIndustryId: number;
    createBy: string,
    createDate: Date,
    type: {
        hasClient: boolean;
        hasIssuer: boolean;
        hasProcessor: boolean;
    };
}

enum EntityTypeEnum {
    Client = "Client",
    Issuer = "Issuer",
    Processor = "Processor",
}

enum EntityType {
    Client = 0,
    Issuer = 1,
    Processor = 2,
}

export { EntityTypeEnum, EntityType }
export type { IEntityItem, IUpsertEntityItem, }