import { FormControl, Grid, Typography } from "@mui/material";
import { StyledFormLabel, ToggleSwitch } from "@components/Common";
import { horizontalFormControlGridLeftStyle, horizontalFormControlGridRightStyle, requiredLabelStyle } from "@styles/global-css-class";
import { IFormControlProperty } from "@definitions/index";
import { FieldError } from "@components/FieldError";
import { memo } from "react";
const ToggleFormControl = ({
  label,
  name,
  value,
  defaultValue,
  onChange,
  isRequired,
  isDisabled,
  isHidden,
  formState,
  xsFormControl = 12,
  mdFormControl = 12,
  xsLabel = 6,
  xsInput,
  textAlign,
  textErrorAlign,
  itemSx,
  textSx,
  ...props
}: IFormControlProperty) => {
  return <Grid container item xs={xsFormControl} md={mdFormControl} data-sentry-element="Grid" data-sentry-component="ToggleFormControl" data-sentry-source-file="ToggleFormControl.tsx">
		<FormControl fullWidth margin='dense' {...props} data-sentry-element="FormControl" data-sentry-source-file="ToggleFormControl.tsx">
			<Grid container data-sentry-element="Grid" data-sentry-source-file="ToggleFormControl.tsx">
				<Grid item xs={xsLabel} sx={itemSx ?? horizontalFormControlGridLeftStyle} data-sentry-element="Grid" data-sentry-source-file="ToggleFormControl.tsx">
					<StyledFormLabel htmlFor={name} aria-describedby={name} hidden={isHidden} error={formState?.errors[name] !== undefined} sx={textSx ?? {
            ...requiredLabelStyle,
            textAlign: textAlign ?? "right"
          }} data-sentry-element="StyledFormLabel" data-sentry-source-file="ToggleFormControl.tsx">
						{label}
						{isRequired && <Typography style={{
              color: "red",
              fontSize: "18px"
            }}>
								*
							</Typography>}
					</StyledFormLabel>
				</Grid>
				<Grid item xs={xsInput ?? (xsLabel === 12 ? 12 : 12 - xsLabel)} sx={itemSx ?? horizontalFormControlGridRightStyle} data-sentry-element="Grid" data-sentry-source-file="ToggleFormControl.tsx">
					<ToggleSwitch id={name} name={name} hidden={isHidden} checked={value ?? defaultValue ?? false} disabled={isDisabled ?? false} onChange={(e: any, checked: boolean) => {
            onChange(checked);
          }} data-sentry-element="ToggleSwitch" data-sentry-source-file="ToggleFormControl.tsx" />
				</Grid>
				{formState && <FieldError formState={formState} name={name} textAlign={textErrorAlign} />}
			</Grid>
		</FormControl>
	</Grid>;
};
export default memo(ToggleFormControl);