import { memo, useEffect, useState } from "react";
import { Grid, Box, FormControl, Typography } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";
import { horizontalFormControlGridLeftStyle, horizontalFormControlGridRightStyle, requiredLabelStyle } from "@styles/global-css-class";
import { StyledFormLabel } from "@components/Common";
import { IFormControlProperty } from "@definitions/index";
import { FieldError } from "@components/FieldError";
function DatePickerFormControl({
  label,
  name,
  value,
  onChange,
  isRequired,
  isDisabled,
  isHidden,
  formState,
  xsFormControl = 12,
  mdFormControl = 12,
  xsLabel = 6,
  xsInput,
  textAlign,
  textErrorAlign,
  itemSx,
  textSx,
  ...props
}: Readonly<IFormControlProperty>) {
  const [dateValue, setDateValue] = useState<Dayjs | null>(null);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [cleared, setCleared] = useState<boolean>(false);
  const handleValue = (dateValue: Dayjs | null) => {
    if (dateValue === null && isRequired) {
      return null;
    } else if (dateValue === null && !isRequired) {
      return undefined;
    } else {
      return dateValue;
    }
  };
  useEffect(() => {
    if (value !== null && value !== undefined && !value.toString().startsWith('0001-01-01')) {
      setDateValue(dayjs(value));
    } else {
      setDateValue(null);
    }
  }, [value]);
  useEffect(() => {
    if (cleared) {
      const timeout = setTimeout(() => {
        setCleared(false);
      }, 300);
      return () => clearTimeout(timeout);
    }
    return () => {};
  }, [cleared]);
  return <Grid container item xs={xsFormControl} md={mdFormControl} data-sentry-element="Grid" data-sentry-component="DatePickerFormControl" data-sentry-source-file="DatePickerFormControl.tsx">
		<FormControl fullWidth margin='dense' {...props} data-sentry-element="FormControl" data-sentry-source-file="DatePickerFormControl.tsx">
			<Grid container data-sentry-element="Grid" data-sentry-source-file="DatePickerFormControl.tsx">
				<Grid item xs={xsLabel} sx={itemSx ?? horizontalFormControlGridLeftStyle} data-sentry-element="Grid" data-sentry-source-file="DatePickerFormControl.tsx">
					<StyledFormLabel aria-describedby={name} hidden={isHidden} error={formState?.errors[name] !== undefined} sx={textSx ?? {
            ...requiredLabelStyle,
            textAlign: textAlign ?? "right",
            color: isFocused ? "#1976d2" : ""
          }} data-sentry-element="StyledFormLabel" data-sentry-source-file="DatePickerFormControl.tsx">
						{label}
						{isRequired && <Typography style={{
              color: "red",
              fontSize: "18px"
            }}>
								*
							</Typography>}
					</StyledFormLabel>
				</Grid>
				<Grid item xs={xsInput ?? (xsLabel === 12 ? 12 : 12 - xsLabel)} sx={itemSx ?? horizontalFormControlGridRightStyle} data-sentry-element="Grid" data-sentry-source-file="DatePickerFormControl.tsx">
					<LocalizationProvider dateAdapter={AdapterDayjs} data-sentry-element="LocalizationProvider" data-sentry-source-file="DatePickerFormControl.tsx">
						<DemoContainer components={["DatePicker"]} data-sentry-element="DemoContainer" data-sentry-source-file="DatePickerFormControl.tsx">
							<Box onFocus={() => setIsFocused(true)} onBlur={() => setIsFocused(false)} data-sentry-element="Box" data-sentry-source-file="DatePickerFormControl.tsx">
								<DatePicker name={name} value={handleValue(dateValue)} onChange={newValue => {
                  if (newValue?.isValid()) {
                    onChange(newValue.toJSON());
                  } else {
                    onChange(newValue?.toString());
                  }
                }} slotProps={{
                  field: {
                    clearable: true,
                    onClear: () => setCleared(true)
                  }
                }} disabled={isDisabled} sx={{
                  "& .MuiInputBase-input": {
                    p: "9px 14px"
                  }
                }} data-sentry-element="DatePicker" data-sentry-source-file="DatePickerFormControl.tsx" />
							</Box>
						</DemoContainer>
					</LocalizationProvider>
				</Grid>
				{formState && <FieldError formState={formState} name={name} textAlign={textErrorAlign} />}
			</Grid>
		</FormControl>
	</Grid>;
}
;
export default memo(DatePickerFormControl);