'use client'

import { AccountInfo, AuthenticationResult, IPublicClientApplication, SilentRequest } from "@azure/msal-browser";
import { getAPIScopes } from "authConfig";

export const getTokenAccess = async (instance: IPublicClientApplication, activeAccount: AccountInfo | null, isCallAPIService: boolean = true) => {
    let silentRequest: SilentRequest = {
        scopes: ["User.Read"],
    };
    if (activeAccount === null) {
        const accounts = instance.getAllAccounts();
        activeAccount = accounts[0];
    }
    if (isCallAPIService) {
        silentRequest = {
            scopes: getAPIScopes(),
            account: activeAccount,
        };
    }
    try {
        console.info("Getting token silently");
        const authResult = await instance.acquireTokenSilent(silentRequest);
        return authResult;
    } catch (e) {
        console.error(e);
        console.error("Logout due to unable to get token silently");
        //Todo: Handle error: ClientConfigurationError: authority_mismatch: Authority mismatch error. 
        //Authority provided in login request or PublicClientApplication config does not match the environment of the provided account. 
        //Please use a matching account or make an interactive request to login to this authority.
        // instance.logoutRedirect({
        //     account: instance.getActiveAccount(),
        //     onRedirectNavigate: () => false,
        // });
    }
}

export const buildRequest = async (auth: AuthenticationResult, method?: 'GET' | 'POST' | 'PUT' | 'DELETE', data?: any) => {
    const { tokenType, accessToken } = auth;
    const reqHeader = {
        method: method ?? 'GET',
        headers: {
            "Content-Type": "application/json",
            "Authorization": `${tokenType} ${accessToken}`
        }
    } as RequestInit;
    if (data !== undefined && data !== null) {
        return {
            ...reqHeader,
            body: JSON.stringify(data)
        }
    }
    return reqHeader;
}

export const buildMultipartFormRequest = async (auth: AuthenticationResult, formData?: any, method?: 'GET' | 'POST' | 'PUT' | 'DELETE') => {
    const { tokenType, accessToken } = auth;
    const reqHeader = {
        method: method ?? 'POST',
        headers: {
            "Authorization": `${tokenType} ${accessToken}`
        },
        body: formData
    } as RequestInit;
    return reqHeader;
}

export async function callApiServiceAsync(url: string, reqHeaders?: RequestInit) {
    const fetchResult = await fetch(url, reqHeaders).then((res) => {
        if (!res.ok) {
            return Promise.resolve(undefined);
        }

        if (res.headers.get('Content-Length') === '0') {
            return undefined;
        }

        return res.json();
    });
    if (!fetchResult) {
        return;
    }
    return fetchResult;
}

export async function callDownloadApiAsync(url: string, reqHeaders?: RequestInit) {
    const response = await fetch(url, reqHeaders);
    if (!response.ok) {
        throw new Error('Failed to download the file');
    }
    return response;
}