import { UUID } from "crypto";
import { IRoleItem } from "./rolePermissionDefinitions";

interface IUserItem {
    id: number;
    userId: UUID;
    displayName: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    status: boolean | null;
    lastLogin: Date | null;
    statusName: string;
    clientName: string;
    roles: string[] | null;
    roleDetails: IRoleItem[] | null;
    createDate: Date | null;
    tenantId: string;
    tenantType: string;
    clients: string[] | null;
};

type UserFilter = {
    keyWord: string;
    client: string;
    role: string;
    page?: number;
    pageSize?: number;
};

interface IInviteEditUserItem {
    type: UserType;
    userId: string;
    emailAddress: string;
    firstName: string;
    lastName: string;
    clientCode: string;
    clientCodes: string[];
    roleIds: string[];
    isAdminRole: boolean;
    tenantId: string;
};

enum UserType {
    Internal = 'internal',
    External = 'external',
}

export { UserType }
export type { UserFilter }
export type { IUserItem, IInviteEditUserItem }