import { FormControl, FormControlLabel, Grid, Radio, RadioGroup, Typography } from "@mui/material";
import { memo } from "react";
import { StyledFormLabel } from "@components/Common";
import { IRadioFormControlProperty } from "@definitions/index";
import { FieldError } from "@components/FieldError";
import { flexCenterProps, flexRowGap1Props, horizontalFormControlGridLeftStyle, paddingX1Props, requiredLabelStyle } from "@styles/global-css-class";
const NewRadioGroupFormControl = ({
  label,
  name,
  value,
  defaultValue,
  onChange,
  isRequired,
  isDisabled,
  listValues,
  isHidden,
  formState,
  xsFormControl = 12,
  mdFormControl = 12,
  xsLabel = 6,
  xsInput,
  textAlign,
  textErrorAlign,
  itemSx,
  textSx,
  isHideLabel,
  labelToolTip,
  isVertical,
  ...props
}: IRadioFormControlProperty) => {
  return <Grid container item xs={xsFormControl} md={mdFormControl} sx={itemSx ?? {
    ...paddingX1Props,
    alignContent: 'flex-start'
  }} data-sentry-element="Grid" data-sentry-component="NewRadioGroupFormControl" data-sentry-source-file="NewRadioGroupFormControl.tsx">
		<FormControl fullWidth margin='normal' {...props} data-sentry-element="FormControl" data-sentry-source-file="NewRadioGroupFormControl.tsx">
			<Grid container sx={flexCenterProps} data-sentry-element="Grid" data-sentry-source-file="NewRadioGroupFormControl.tsx">
				<Grid item xs={xsLabel} sx={horizontalFormControlGridLeftStyle} data-sentry-element="Grid" data-sentry-source-file="NewRadioGroupFormControl.tsx">
					<StyledFormLabel aria-describedby={name} hidden={isHidden} error={formState?.errors[name] !== undefined} sx={textSx ?? {
            ...requiredLabelStyle,
            textAlign: textAlign ?? "right"
          }} data-sentry-element="StyledFormLabel" data-sentry-source-file="NewRadioGroupFormControl.tsx">
						{label}
						{labelToolTip}
						{isRequired && <Typography style={{
              color: "red",
              fontSize: "18px"
            }}>
								*
							</Typography>}
					</StyledFormLabel>
				</Grid>
				<Grid item xs={xsInput ?? (xsLabel === 12 ? 12 : 12 - xsLabel)} sx={flexRowGap1Props} data-sentry-element="Grid" data-sentry-source-file="NewRadioGroupFormControl.tsx">
					<RadioGroup id={`${name}-radio-group`} aria-labelledby={`${name}-radio-buttons-group-label`} value={value ?? defaultValue ?? ''} name={`${name}-radio-buttons-group`} onChange={e => {
            onChange(e.target.value);
          }} data-sentry-element="RadioGroup" data-sentry-source-file="NewRadioGroupFormControl.tsx">
						<Grid container item xs={12} data-sentry-element="Grid" data-sentry-source-file="NewRadioGroupFormControl.tsx">
							{listValues?.map(item => <Grid key={`${name}-${item.value}`} item xs={isVertical ? 12 : 6} sx={{
                textAlign: isVertical ? "left" : "center"
              }}>
									<FormControlLabel key={`${name}-${item.value}`} sx={{
                  m: 0
                }} value={item.value} control={<Radio id={`${name}-radio-${item.name}`} name={item.name} disabled={isDisabled} color="default" />} label={isHideLabel === true ? "" : item.name} />
								</Grid>)}
						</Grid>

					</RadioGroup>
				</Grid>
				{formState && <FieldError formState={formState} name={name} textAlign={textErrorAlign} />}
			</Grid>
		</FormControl>
	</Grid>;
};
export default memo(NewRadioGroupFormControl);