"use client";

const B2C_LOGIN_TYPE = "B2C";
const B2B_LOGIN_TYPE = "B2B";
const LOGIN_TYPE_STORAGE_NAME = "login-type";

export {
	B2C_LOGIN_TYPE,
	B2B_LOGIN_TYPE,
	LOGIN_TYPE_STORAGE_NAME
};

export const REPORT_VISUAL_KEY = {
	ESCHEAT_DASHBOARD__ESCHEAT_BY_PRIORITY: "dashboard_escheat_by_priority",
	ESCHEAT_DASHBOARD__FORECASTED_AND_CURRENT: "dashboard_escheat_forecasted_and_current",
};
