import React, { memo, useState } from "react";
import { FormControl, Grid, TextField, Typography } from "@mui/material";
import { horizontalFormControlGridLeftStyle, horizontalFormControlGridRightStyle, requiredLabelStyle } from "@styles/global-css-class";
import { StyledFormLabel } from "@components/Common";
import { IFormControlProperty } from "@definitions/index";
import { FieldError } from "@components/FieldError";
function MultilineTextFormControl({
  label,
  name,
  value,
  defaultValue,
  placeHolder,
  onChange,
  isRequired,
  isDisabled,
  isHidden = false,
  formState,
  xsFormControl = 12,
  mdFormControl = 12,
  xsLabel = 6,
  xsInput,
  textAlign,
  textErrorAlign,
  itemSx,
  textSx,
  ...props
}: Readonly<IFormControlProperty>) {
  const [isTextFieldFocused, setIsTextFieldFocused] = useState(false);
  return <Grid container item xs={xsFormControl} md={mdFormControl} data-sentry-element="Grid" data-sentry-component="MultilineTextFormControl" data-sentry-source-file="MultilineTextFormControl.tsx">
		<FormControl fullWidth margin='dense' {...props} data-sentry-element="FormControl" data-sentry-source-file="MultilineTextFormControl.tsx">
			<Grid container data-sentry-element="Grid" data-sentry-source-file="MultilineTextFormControl.tsx">
				<Grid item xs={xsLabel} sx={itemSx ?? {
          ...horizontalFormControlGridLeftStyle,
          alignItems: "start"
        }} data-sentry-element="Grid" data-sentry-source-file="MultilineTextFormControl.tsx">
					<StyledFormLabel htmlFor={name} hidden={isHidden} aria-describedby={name} error={formState?.errors[name] !== undefined} sx={textSx ?? {
            ...requiredLabelStyle,
            textAlign: textAlign ?? "right",
            color: isTextFieldFocused ? "#1976d2" : ""
          }} data-sentry-element="StyledFormLabel" data-sentry-source-file="MultilineTextFormControl.tsx">
						{label}
						{isRequired && <Typography style={{
              color: "red",
              fontSize: "18px"
            }}>
								*
							</Typography>}
					</StyledFormLabel>
				</Grid>
				<Grid item xs={xsInput ?? (xsLabel === 12 ? 12 : 12 - xsLabel)} sx={itemSx ?? horizontalFormControlGridRightStyle} data-sentry-element="Grid" data-sentry-source-file="MultilineTextFormControl.tsx">
					<TextField id={name} name={name} value={value ?? defaultValue ?? ''} onChange={e => {
            onChange(e.target.value);
          }} placeholder={placeHolder} error={formState?.errors[name] !== undefined} disabled={isDisabled ?? false} hidden={isHidden} multiline rows={3} sx={{
            width: "100%"
          }} onFocus={() => setIsTextFieldFocused(true)} onBlur={() => setIsTextFieldFocused(false)} data-sentry-element="TextField" data-sentry-source-file="MultilineTextFormControl.tsx" />
				</Grid>
				{formState && <FieldError formState={formState} name={name} textAlign={textErrorAlign} />}
			</Grid>
		</FormControl>
	</Grid>;
}
;
export default memo(MultilineTextFormControl);