import { FormControl, Grid, TextField } from "@mui/material";
import React, { memo } from "react";
import { IFormControlProperty } from "@definitions/index";
import { FieldError } from "@components/FieldError";
import { customDisabledOutlineInput, paddingX1Props } from "@styles/global-css-class";
function NewMultilineTextFormControl({
  label,
  name,
  value,
  defaultValue,
  placeHolder,
  onChange,
  isRequired,
  isDisabled,
  isHidden = false,
  formState,
  xsFormControl = 12,
  mdFormControl = 12,
  xsLabel = 6,
  xsInput,
  textAlign,
  textErrorAlign,
  itemSx,
  textSx,
  ...props
}: Readonly<IFormControlProperty>) {
  return <Grid container item xs={xsFormControl} md={mdFormControl} sx={itemSx ?? {
    ...paddingX1Props,
    alignContent: 'flex-start'
  }} data-sentry-element="Grid" data-sentry-component="NewMultilineTextFormControl" data-sentry-source-file="NewMultilineTextFormControl.tsx">
		<FormControl fullWidth margin='normal' {...props} data-sentry-element="FormControl" data-sentry-source-file="NewMultilineTextFormControl.tsx">
			<TextField id={name} name={name} value={value ?? defaultValue ?? ''} onChange={e => {
        onChange(e.target.value);
      }} placeholder={placeHolder} error={formState?.errors[name] !== undefined} required={isRequired} disabled={isDisabled ?? false} hidden={isHidden} type="text" multiline rows={3} label={label} sx={{
        width: "100%",
        ...customDisabledOutlineInput
      }} data-sentry-element="TextField" data-sentry-source-file="NewMultilineTextFormControl.tsx" />
			{formState && <FieldError formState={formState} name={name} textAlign={textErrorAlign} />}
		</FormControl>
	</Grid>;
}
;
export default memo(NewMultilineTextFormControl);