import { defaultAutoCompleteProperties, defaultDateProperties, defaultDropDownProperties, defaultMultipleSelectProperties, defaultStringProperties, IPropertyType } from "./definitions";

interface ISimulationConditionItem {
    clientCode: string;
    programCode: string;
    states: string[];
    reportDateToProcess: string;
    description: string;
}

export const simulationResponsiveProps = {
    xsFormControl: 6,
    mdFormControl: 6,
}

export const simulationStringProperties: IPropertyType = {
    ...defaultStringProperties,
    ...simulationResponsiveProps,
}

export const simulationAutoCompleteProperties: IPropertyType = {
    ...defaultAutoCompleteProperties,
    ...simulationResponsiveProps,
}

export const simulationDropDownProperties: IPropertyType = {
    ...defaultDropDownProperties,
    ...simulationResponsiveProps,
}

export const simulationMultipleSelectProperties: IPropertyType = {
    ...defaultMultipleSelectProperties,
    ...simulationResponsiveProps,
}

export const simulationDateProperties: IPropertyType = {
    ...defaultDateProperties,
    ...simulationResponsiveProps,
}

export type { ISimulationConditionItem, }