"use client";

import { BrowserCacheLocation, Configuration, LogLevel, RedirectRequest, SilentRequest, } from "@azure/msal-browser";
import { isB2cUserLoggingIn } from "@utils/helpers";

const loggerCallback = (level: LogLevel, message: string, containsPii: boolean): any => {
    if (containsPii) {
        return;
    }
    switch (level) {
        case LogLevel.Error:
            console.error(message);
            return;
        case LogLevel.Info:
            console.info(message);
            return;
        case LogLevel.Verbose:
            console.debug(message);
            return;
        case LogLevel.Warning:
            console.warn(message);
            return;
        default:
            console.log(message);
            return;
    }
};

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
    auth: {
        clientId: process.env.NEXT_PUBLIC_AAD_CLIENT_ID!,
        authority: `https://login.microsoftonline.com/${process.env.NEXT_PUBLIC_AAD_TENANT_ID!}/v2.0`,
        knownAuthorities: [],
        redirectUri: process?.env.NEXT_PUBLIC_AAD_REDIRECT_URI ?? '/',
        postLogoutRedirectUri: process?.env.NEXT_PUBLIC_AAD_POST_LOGOUT_REDIRECT_URI ?? '/',
        // If the condition is true, then the user should be redirected to the login page of the origin route. (at here: /login)
        navigateToLoginRequestUrl: false
    },
    cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage, // "sessionStorage"
        claimsBasedCachingEnabled: true,
        storeAuthStateInCookie: false,
    },
    system: {
        allowRedirectInIframe: true,
        allowNativeBroker: false, // Disables WAM Broker
        loggerOptions: {
            logLevel: LogLevel.Error,
            loggerCallback: loggerCallback,
            piiLoggingEnabled: false,
        }
    }
};

export const b2cMsalConfig: Configuration = {
    auth: {
        clientId: process.env.NEXT_PUBLIC_B2C_CLIENT_ID!,
        authority: process.env.NEXT_PUBLIC_B2C_SIGNIN_URL!,
        knownAuthorities: [process.env.NEXT_PUBLIC_B2C_KNOWN_AUTHORITIES!],
        redirectUri: process?.env.NEXT_PUBLIC_B2C_REDIRECT_URI ?? '/',
        // If the condition is true, then the user should be redirected to the login page of the origin route. (at here: /login)
        navigateToLoginRequestUrl: false
    },
    cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage,
        claimsBasedCachingEnabled: true,
        storeAuthStateInCookie: false,
    },
    system: {
        allowNativeBroker: false,
        loggerOptions: {
            logLevel: LogLevel.Error,
            loggerCallback: loggerCallback,
            piiLoggingEnabled: false,
        }
    }
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: RedirectRequest = {
    scopes: [process.env.NEXT_PUBLIC_AAD_FUNCTION_SCOPE_URI ?? "", "User.Read"].filter(scp => scp !== "")

};

export const silentRequest: SilentRequest = {
    scopes: [process.env.NEXT_PUBLIC_AAD_FUNCTION_SCOPE_URI ?? "", "User.Read"].filter(scp => scp !== ""),
};

export const b2cLoginRequest: RedirectRequest = {
    scopes: [process.env.NEXT_PUBLIC_B2C_FUNCTION_SCOPE_URI ?? "", "openid", "offline_access", 'profile'].filter(scp => scp !== ""),
};
export const b2cSilentRequest: SilentRequest = {
    scopes: [process.env.NEXT_PUBLIC_B2C_FUNCTION_SCOPE_URI ?? "", "openid", "offline_access", 'profile'].filter(scp => scp !== ""),
};

export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/beta/me"
};

export const getAPIScopes = () => {
    if (isB2cUserLoggingIn()) {
        return [process.env.NEXT_PUBLIC_B2C_FUNCTION_SCOPE_URI ?? "", "openid", "profile"].filter(scp => scp !== "");
    }
    return [process.env.NEXT_PUBLIC_AAD_FUNCTION_SCOPE_URI ?? "", "openid", "profile"].filter(scp => scp !== "");
}

export const getLoginRequest = () => {
    if (isB2cUserLoggingIn()) {
        return b2cLoginRequest;
    }
    return loginRequest;
}

export const getSilentRequest = () => {
    if (isB2cUserLoggingIn()) {
        return b2cSilentRequest;
    }
    return silentRequest;
}

export const getMsalConfig = () => {
    if (isB2cUserLoggingIn()) {
        return b2cMsalConfig;
    }
    return msalConfig;
}