import { verticalFormControlGridStyle } from "@styles/global-css-class";
import { FormState, IPropertyType, defaultCheckboxProperties, defaultDropDownProperties, defaultStringProperties } from "./definitions";
import { IEntityItem } from "./entitiesDefinitions";

interface IClientItem {
    id: number;
    clientCode: string;
    clientName: string;
    description: string;
    billingContactFirstName?: string;
    billingContactLastName?: string;
    billingContactPhone?: string;
    billingContactEmail?: string;
    billingAddress?: string;
    billingAddress2?: string;
    billingCity?: string;
    billingState?: string;
    billingZip?: string;
    companyType?: string;
    corporateFilingAddress?: string;
    corporateFilingAddress1?: string;
    corporateFilingAddress2?: string;
    corporateFilingCity?: string;
    corporateFilingContact1Address1?: string;
    corporateFilingContact1Address2?: string;
    corporateFilingContact1Address3?: string;
    corporateFilingContact1City?: string;
    corporateFilingContact1Country?: string;
    corporateFilingContact1Email?: string;
    corporateFilingContact1Fax?: string;
    corporateFilingContact1Name?: string;
    corporateFilingContact1Phone?: string;
    corporateFilingContact1State?: string;
    corporateFilingContact1Title?: string;
    corporateFilingContact1Zip?: string;
    corporateFilingContact2Address1?: string;
    corporateFilingContact2Address2?: string;
    corporateFilingContact2Address3?: string;
    corporateFilingContact2City?: string;
    corporateFilingContact2Country?: string;
    corporateFilingContact2Email?: string;
    corporateFilingContact2Fax?: string;
    corporateFilingContact2Name?: string;
    corporateFilingContact2Phone?: string;
    corporateFilingContact2State?: string;
    corporateFilingContact2Zip?: string;
    corporateFilingCountry?: string;
    corporateFilingCounty?: string;
    corporateFilingINCDate?: string;
    corporateFilingName?: string;
    corporateFilingNAICSCode?: string;
    corporateFilingNumberOfEmployees?: string;
    corporateFilingSICCode?: string;
    corporateFilingState?: string;
    corporateFilingTaxId?: string;
    corporateFilingTaxIdExt?: string;
    corporateFilingZip?: string;
    corporateFilingContact2Title?: string;
    corporateFilingFax?: string;
    corporateFilingINCState?: string;
    corporateFilingLastYearFiscalRevenue?: string;
    holderCorporateName?: string;
    monthsToEarnings?: string;
    principlePlaceOfBusinessAddress?: string;
    principlePlaceOfBusinessAddress2?: string;
    principlePlaceOfBusinessCity?: string;
    principlePlaceOfBusinessState?: string;
    principlePlaceOfBusinessZip?: string;
    technicalContactEmail?: string;
    technicalContactFirstName?: string;
    technicalContactLastName?: string;
    technicalContactPhone?: string;
    sic?: string;
    runTaxReport?: boolean;
    taxPeriodEndDate?: string;
    calendarNumberOfDaysToAdjust?: string;
    calendarEndDateToAdjust?: string;
    calendarStartDate?: string;
    fiscalCalendarType?: string;
    corporateFilingTotalAssets?: string;
    optiForDataRange?: string;
    status?: boolean;
    userId?: string;
    frequencyOfCardData?: string;
    margin?: string;
    optiForProcessExcludedCards?: string;
    escheatProduct?: string;
    runOptiEarnings?: boolean;
    applyProvisionalSeasonalAdjustment?: boolean;
    factorType?: string;
    feeStartMonth?: string;
    feeStopMonth?: string;
    primarilySellingTangiblePersonalPropertyAtRetail?: boolean;
    isQualifiedRestaurantRule?: boolean;
    inboundFileLocation?: string;
    inboundFileID?: string;
    inboundPushPull?: string;
    processorID?: string;
    currencyCodeAlpha?: string;
    entityId: number | null;
    issuerType: string;
    clientDisplayName: string;
    program: IProgramItem | null;
    programProfileAttributes: IProgramProfileAttribute[] | null;
    clientProfileAttributes: IClientProfileAttribute[] | null;
    entity: IEntityItem;
};

interface IClientProfileAttribute {
    id: number;
    attributeId: number;
    clientCode: string;
    processorCode: string;
    attributeName: string;
    attributeValue: string;
    attributeType: string;
    active: boolean;
    createdDate: Date
};

interface IOrganizationItem {
    id: number;
    code: string;
    name: string;
    description: string;
    type: number;
    entityName: string;
};

type OrganizationState = FormState & {
    id?: number;
    type?: number;
};

interface IAddOrganizationItem {
    code: string;
    name: string;
    description: string;
    type: number;
}

interface IIssuerItem {
    id: number;
    code: string;
    name: string;
    description: string;
    entityId: number | null;
    issuerType: string;
    entity: IEntityItem;
};


interface IProcessorItem {
    id: number;
    code: string;
    name: string;
    description: string;
    entityId: number | null;
    processorDisplayName: string;
    entity: IEntityItem;
};

interface IProgramProfileAttribute {
    id: number;
    attributeId: number;
    clientCode: string;
    programCode: string;
    attributeName: string;
    attributeValue: string;
    attributeType: string;
    active: boolean;
    createdDate: Date
};

interface IProgramItem {
    id: number;
    clientCode: string;
    programCode: string;
    processorCode: string;
    escheatProduct: string;
    frequencyOfCardData: string;
    name: string;
    programDescription: string;
    status: string;
    runTaxReport: boolean;
    leadIssuerId: number | null;
    otherIssuerId: number | null;
    leadIssuerType: string;
    otherIssuerType: string;
    cardCompliantClientNumber: string;
    programDisplayName: string;
    programProfileAttributes: IProgramProfileAttribute[];
};

interface ICurrencyItem {
    currencyCodeAlpha: string;
    currencySymbol: string;
    currencyDisplayName: string;
};

const organizationTypes = [
    { name: 'Client', value: 0 },
    { name: 'Issuer', value: 1 },
    { name: 'Processor', value: 2 },
]

const organizationTypeEnum = ["Client", "Issuer", "Processor"];

const clientStringProperties: IPropertyType = {
    ...defaultStringProperties,
    xsFormControl: 6,
    mdFormControl: 3,
    xsLabel: 12,
    textAlign: "left",
    itemSx: verticalFormControlGridStyle,
}

const clientDropDownProperties: IPropertyType = {
    ...defaultDropDownProperties,
    xsFormControl: 6,
    mdFormControl: 3,
    xsLabel: 12,
    textAlign: "left",
    itemSx: verticalFormControlGridStyle,
}

const clientCheckboxProperties: IPropertyType = {
    ...defaultCheckboxProperties,
    xsFormControl: 6,
    mdFormControl: 3,
    xsLabel: 12,
    textAlign: "left",
    itemSx: verticalFormControlGridStyle,
}

const issuerTypes = [
    "Federal Financial Institution (US)",
    "State Financial Institution (US)",
    "Money Transmitter (US)",
    "Foreign Financial Institution",
    "Non-Bank First Party (Retailer)",
    "Non- Bank Third Party (GiftCo)",
    "Non- Bank Third Party (Non-GiftCo)",
]

enum ProfileAttributeNameEnum {
    DoesCardCompliantDeliverNotices = "DoesCardCompliantDeliverNotices",
    DoesCardCompliantReceiveResponses = "DoesCardCompliantReceiveResponses",
    CurrencyCodeAlpha = "CurrencyCodeAlpha",
}

export {
    organizationTypeEnum,
    organizationTypes,
    clientStringProperties,
    clientDropDownProperties,
    clientCheckboxProperties,
    issuerTypes,
    ProfileAttributeNameEnum
}
export type { OrganizationState, }
export type {
    IClientItem,
    IOrganizationItem,
    IAddOrganizationItem,
    IIssuerItem,
    IProcessorItem,
    IProgramItem,
    IProgramProfileAttribute,
    ICurrencyItem
}