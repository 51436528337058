import { FormState, IPropertyType, defaultDateProperties, defaultDropDownProperties, defaultMultiLineProperties, defaultNumberProperties, defaultStringProperties, defaultToggleProperties } from "./definitions";

interface IStateEscheat {
    escheatAmountTrigger: string | null;
    escheatAmountMethod: string | null;
    escheatAmount: string | null;
    registeredOwnerRecipientOnly: boolean;
    promotionalExemption: boolean;
    noConsiderationExemption: boolean;
    monetaryConsiderationExemption: boolean;
    nonMonetaryConsiderationExemption: boolean;
    expirationDateEscheatExemption: boolean;
    derivativeRightsExemption: boolean;
}

interface IStateProfileDetail {
    id: number;
    profileId: string;
    stateId: string;
    domicileDefinition: string;
    thirdPriorityProvision: boolean;
    isGeneral2ndPriorityExemption: boolean;
    isStateSpecific2ndPriorityExemption: boolean;
    copiedFrom: string;
    legalAnnotation: string;
    adminDeductionProvision?: boolean;
    adminDeductionMethod: string;
    adminDeductionAmount?: string;
    adminDeductionPercentage?: string;
    lastKnownAddressDefinition: boolean;
    originStateProfileID?: number;
    retailer: IStateProfileRetailerItem,
    notice: IStateProfileNoticeItem,
    filing: IStateProfileFilingItem,
}

interface IRetailTypeItem {
    id: number;
    typeName: string;
    description: string;
}

interface IGenreItem {
    id: number;
    genreName: string;
    shortName: string;
    description: string;
}

interface IProfileIdItem {
    stateProfileId: number;
    profileId: string;
}

interface IStateProfileRetailerItem {
    id: number;
    stateProfileID: number;
    genre: string;
    retailType: string;
    abandonTrigger: string;
    deemedRegisteredProvision: boolean;
    exemptMinProvision: boolean;
    exemptMinTrigger: string;
    exemptMinAmount: string;
    qualifiedRestaurantExemptMinProvision: boolean;
    qualifiedRestaurantExemptMinTrigger: string;
    qualifiedRestaurantExemptMinAmount: string;
    abandonStartMonth: string;
    abandonPeriod: string;
    abandonExpirePeriod: string;
    abandonPeriodConditional: string;
    reportingYearStart: Date | null;
    reportingYearEnd: Date | null;
    filingDueDate: Date | null;
    transitionCutoffDate: Date | null;
    transitionFilingDate: Date | null;
    expressLapSilentForCashPaid: string;
    pricePaidLapSilentForCashPaid: string;
    possMultiRetailExemption: string;
    escheatProvision: boolean;
    escheatAmountTrigger: string;
    escheatAmountMethod: string;
    escheatAmount: string;
    expireProvision: boolean;
    reverseExpireProvision: boolean;
    programFeeProvision: boolean;
    cashBackProvision: boolean;
    reloadProvision: boolean;
    cashBackReloadProvision: boolean;
    cashBackImposeAmount: string;
    cashBackImpose: boolean;
    exemptIssuerPrimarilySellingTangiblePersonalPropertyAtRetail: boolean;
    regulatoryAuthorityEscheat: boolean;
    registeredOwnerRecipientOnly: boolean;
    firstPriorityProvision: boolean;
    promotionalExemption: boolean;
    noConsiderationExemption: boolean;
    monetaryConsiderationExemption: boolean;
    nonMonetaryConsiderationExemption: boolean;
    expirationDateEscheatExemption: boolean;
    derivativeRightsExemption: boolean;
    is1stPrioritySpecificExemption: boolean;
    is1stPriorityImplicitExemption: boolean;
    effectiveDate: Date | null;
    effectiveDateType: string;
};

interface IStateProfileNoticeItem {
    id: number;
    stateProfileID: number;
    noticeThresholdAmount?: string;
    statuteOfLimitations?: string;
    noticeRequired: boolean;
    requiresUSMail?: boolean;
    requiresUSMailAndEnvelope?: boolean;
    requiresCertifiedMail?: boolean;
    requiresCertifiedMailAndEnvelope?: boolean;
    certifiedMailThreshold?: string;
    allowsEmail?: boolean;
    requiresPublication?: boolean;
    publicationThreshold?: string;
    earliestToSend?: string;
    latestToSend?: string;
    stateProfile_RetailerKey?: number;
    earliestPublication?: string;
};

interface IStateProfileFilingItem {
    id: number;
    stateProfileID: number;
    propertyType: string;
    aggPropertyType: string;
    aggregateMax?: string;
    relationToOwner: string;
    mediaType: string;
    deductionCode: string;
    encryptionRequired?: boolean;
    negativeFilingRequired?: boolean;
    negativeFilingPermitted?: boolean;
    negativeFilingProhibited?: boolean;
    amexPropertyType: string;
    genre: string;
};

type NewDraftFormState = FormState & {
    stateProfileID?: number;
    genre?: string;
    retailType?: string;
};

interface IStateProfile {
    stateProfileId: number;
    stateId: string;
    profileId: string;
    retailerId: number;
    genre: string;
    retailType: string;
    createdDate: Date;
    isDraft: boolean;
    stateDisplayName: string;
}

type PublishDraftDto = {
    stateProfileId: number,
    retailType?: string;
    genre?: string;
}

type DraftProfileDto = {
    stateId?: string,
    profileId: string,
    retailType?: string;
    genre?: string;
}

const stateProfileResponsiveProps = {
    xsFormControl: 12,
    mdFormControl: 6,
}

const stateProfileDropDownProperties: IPropertyType = {
    ...defaultDropDownProperties,
    ...stateProfileResponsiveProps,
}

const stateProfileStringProperties: IPropertyType = {
    ...defaultStringProperties,
    ...stateProfileResponsiveProps,
}

const stateProfileMultiLineProperties: IPropertyType = {
    ...defaultMultiLineProperties,
    ...stateProfileResponsiveProps,
}

const stateProfileToggleProperties: IPropertyType = {
    ...defaultToggleProperties,
    ...stateProfileResponsiveProps,
}

const stateProfileDateProperties: IPropertyType = {
    ...defaultDateProperties,
    ...stateProfileResponsiveProps,
}

const stateProfileNumberProperties: IPropertyType = {
    ...defaultNumberProperties,
    ...stateProfileResponsiveProps,
}

export type { NewDraftFormState, PublishDraftDto, DraftProfileDto, }
export type {
    IStateEscheat,
    IStateProfile,
    IStateProfileDetail,
    IStateProfileRetailerItem,
    IStateProfileNoticeItem,
    IStateProfileFilingItem,
    IProfileIdItem,
    IGenreItem,
    IRetailTypeItem,
}
export {
    stateProfileDropDownProperties,
    stateProfileStringProperties,
    stateProfileToggleProperties,
    stateProfileMultiLineProperties,
    stateProfileDateProperties,
    stateProfileNumberProperties,
}