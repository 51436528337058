import { CustomCellRendererProps, CustomHeaderProps } from "ag-grid-react";
import { ICategory } from "./reportDefinitions";

interface IReportConfiguration {
    id?: number;
    reportId: number;
    roleId: number;
    categoryId: number;
    reportName?: string;
    isReportViewable: boolean;
};

enum RoleGroup {
    Internal = 0,
    External = 1,
}

enum RoleType {
    Admin = 'admin',
    User = 'user',
}

interface IRoleItem {
    id: number;
    name: string;
    description: string;
    group: number;
    groupName: string;
    createDate: Date;
    lastModifyDate: Date;
    reportConfigurations: IReportConfiguration[];
};

interface IRolePermission {
    id?: number;
    roleId: number;
    roleName: string;
    group: string;
    isReportViewable: boolean;
};

interface IReportAndRoles {
    reportId: number;
    reportName: string;
    categoryNames: string[];
    categories: ICategory[];
    rolePermissions: IRolePermission[]
};

interface ICellCheckboxParams extends CustomCellRendererProps {
    isAADAdminRole: boolean;
    roleId: number;
    reports: IReportAndRoles[];
    handleUpdateVisible: (params: ICellCheckboxParams, newRolePermission: IRolePermission, checked: boolean) => void;
}

interface ICellCheckboxHeaderProps extends CustomHeaderProps {
    isAADAdminRole: boolean;
    roleId: number;
    isChecked: (params: ICellCheckboxHeaderProps) => boolean;
    handleAllVisible: (params: ICellCheckboxHeaderProps, checked: boolean) => void;
}

export { RoleGroup, RoleType }
export type { IReportConfiguration, IRoleItem, IReportAndRoles, IRolePermission, ICellCheckboxParams, ICellCheckboxHeaderProps }