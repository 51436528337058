import { Box, FormHelperText } from "@mui/material";
import { FieldErrorProps } from "@definitions/index";
const FieldError = ({
  formState,
  name,
  textAlign
}: FieldErrorProps) => {
  return <Box sx={{
    width: "100%"
  }} id={`${name}-error`} aria-live='polite' aria-atomic='true' data-sentry-element="Box" data-sentry-component="FieldError" data-sentry-source-file="FieldError.tsx">
        {formState?.errors[name]?.map((error: string) => <FormHelperText sx={{
      textAlign: textAlign ?? 'left'
    }} error key={error}>
                    {error}
                </FormHelperText>)}
    </Box>;
};
export { FieldError };