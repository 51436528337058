import { defaultAutoCompleteProperties, defaultDropDownProperties, defaultMultiLineProperties, defaultStringProperties, IPropertyType } from "./definitions";

interface IDataLayoutItem {
    id: number;
    dataLayoutName: string;
    formatTypeId: number;
    tableTemplateId: number;
    commentCharacter: string;
    textQualifier: string;
    columnDelimiter: string; //1 Column Delimiter
    xmlRowTag: string; //300 XML Row Tag
    dataAddress: string; //100 Data Address
    summaryDataRowsCount: number;
    dataRowRecordType: string; //3 Data Row Record Type
    dataRowColumnWidths: string; //300 Data Row Column Widths
    headerRowRecordType: string; //1 Header Row Record Type
    headerRowColumnCounts: string; //300 Header Row Column Counts
    trailerRowRecordType: string; //1 Trailer Row Record Type
    trailerRowColumnWidths: string; //300 Trailer Row Column Counts
    siblingDataRowRecordType: string;
    hasColumnHeader: boolean;
    hasTrailer: boolean;
    hasFileInfo: boolean;

    dataLayoutFields: IDataLayoutFieldItem[] | null;
    dataLayoutFieldMappings: IDataLayoutFieldMappingItem[] | null
}

export interface IDataLayoutFieldItem {
    dataLayoutId: number;
    recordType: string;
    fieldName: string;
    fieldTypeId: string;
    expectation: string;
    expectationAction: string;
    fieldDescription: string;
    fieldIndexNumber: number | null;
    maximumLength: number | null;
    startingPosition: number | null;
    pci: boolean | null;
    pii: boolean;
    fingerPrint: boolean;
    cardIdentifier: boolean | null;
    transactionIdentifier: boolean | null;
    statusIdentifier: boolean | null;
    accountIdentifier: boolean | null;
    storeNumberIdentifier: boolean | null;
    rowNumber: number | null;
    notes: string;
    fieldIndicator: string;

    bronzeFileType: IBronzeFieldTypeItem | null;
}

export const dataLayoutResponsiveProps = {
    xsFormControl: 6,
    mdFormControl: 6,
}

export const dataLayoutStringProperties: IPropertyType = {
    ...defaultStringProperties,
    ...dataLayoutResponsiveProps,
}

export const dataLayoutAutoCompleteProperties: IPropertyType = {
    ...defaultAutoCompleteProperties,
    ...dataLayoutResponsiveProps,
}

export const dataLayoutDropDownProperties: IPropertyType = {
    ...defaultDropDownProperties,
    ...dataLayoutResponsiveProps,
}

export const dataLayoutMultiLineProperties: IPropertyType = {
    ...defaultMultiLineProperties,
    ...dataLayoutResponsiveProps,
}

export interface IDataLayoutFieldMappingItem {
    id: number;
    dataLayoutId: number;
    targetTableTemplateId: number;
    targetFieldName: string;
    targetFieldType: string;
    targetFieldFormat: string;
    sourceTableTemplateID: number;
    sourceFieldName: string;
    expectation: string;
    expectationAction: string;
    defaultValue: string;
    notes: string;
    generateFromFingerprint: boolean | null;
    primaryKey: boolean | null;
    targetTable: ITableTemplateItem | null;
    sourceTable: ITableTemplateItem | null;
}

export interface ITableTemplateItem {
    id: number;
    description: string;
    tableName: string;
    version: string;
    bronzeStorageLocation: string;
    silverStorageLocation: string;
    domainName: string;
    lookupTable: boolean | null;
    active: boolean | null;
}

interface IDataLayoutFormatTypeItem {
    id: number;
    formatType: string;
}

interface IBronzeFieldTypeItem {
    id: number;
    fieldType: string;
    label: string;
}

export type { IDataLayoutItem, IDataLayoutFormatTypeItem }