import { FormControl, Grid, Autocomplete, TextField } from "@mui/material";
import React, { memo } from "react";
import { customDisabledOutlineInput, paddingX1Props } from "@styles/global-css-class";
import { FieldError } from "@components/FieldError";
import { IAutoCompleteFormControlProperty } from "@definitions/definitions";
function NewAutoCompleteFormControl({
  label,
  name,
  value,
  defaultValue,
  onChange,
  isRequired,
  isDisabled,
  isHidden,
  showSelect,
  listValues,
  placeHolder,
  formState,
  zIndex,
  xsFormControl = 12,
  mdFormControl = 12,
  xsLabel = 6,
  xsInput,
  textAlign,
  textErrorAlign,
  itemSx,
  textSx,
  isFreeSolo,
  ...props
}: Readonly<IAutoCompleteFormControlProperty>) {
  return <Grid container item xs={xsFormControl} md={mdFormControl} sx={itemSx ?? {
    ...paddingX1Props,
    alignContent: 'flex-start'
  }} data-sentry-element="Grid" data-sentry-component="NewAutoCompleteFormControl" data-sentry-source-file="NewAutoCompleteFormControl.tsx">
		<FormControl fullWidth margin='normal' {...props} required={isRequired} data-sentry-element="FormControl" data-sentry-source-file="NewAutoCompleteFormControl.tsx">
			<Autocomplete disablePortal freeSolo={isFreeSolo} options={listValues} disabled={isDisabled} value={value ?? defaultValue ?? ''} isOptionEqualToValue={(option, value) => option === value} getOptionKey={option => option} getOptionLabel={(option: any) => option} sx={{
        maxHeight: 200,
        "@media screen and (max-width: 1366px) and (max-height: 768px)": {
          maxHeight: 136
        },
        ...customDisabledOutlineInput
      }} onChange={(e: any, newValue: any) => {
        onChange(newValue);
      }} renderInput={params => <TextField {...params} name={name} value={value} label={label} onChange={e => {
        if (isFreeSolo) {
          onChange(e.target.value);
        }
      }} />} componentsProps={{
        paper: {
          sx: {
            wordBreak: "break-word"
          }
        }
      }} data-sentry-element="Autocomplete" data-sentry-source-file="NewAutoCompleteFormControl.tsx" />
			{formState && <FieldError formState={formState} name={name} textAlign={textErrorAlign} />}
		</FormControl>
	</Grid>;
}
export default memo(NewAutoCompleteFormControl);