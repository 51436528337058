import React, { memo } from "react";
import { Checkbox, FormControl, FormControlLabel, Grid, Typography } from "@mui/material";
import { horizontalFormControlGridLeftStyle, horizontalFormControlGridRightStyle, requiredLabelStyle } from "@styles/global-css-class";
import { ICheckboxFormControlProperty } from "@definitions/index";
import { FieldError } from "@components/FieldError";
import { StyledFormLabel } from "@components/Common";
function CheckboxFormControl({
  label,
  value,
  name,
  defaultValue,
  placeHolder,
  isRequired,
  isDisabled,
  isHidden = false,
  onChange,
  formState,
  xsFormControl = 12,
  mdFormControl = 12,
  xsLabel = 6,
  xsInput,
  textAlign,
  textErrorAlign,
  itemSx,
  textSx,
  isShowCheckboxLabel,
  ...props
}: Readonly<ICheckboxFormControlProperty>) {
  return <Grid container item xs={xsFormControl} md={mdFormControl} data-sentry-element="Grid" data-sentry-component="CheckboxFormControl" data-sentry-source-file="CheckboxFormControl.tsx">
		<FormControl fullWidth margin='dense' {...props} data-sentry-element="FormControl" data-sentry-source-file="CheckboxFormControl.tsx">
			<Grid container data-sentry-element="Grid" data-sentry-source-file="CheckboxFormControl.tsx">
				<Grid item xs={xsLabel} sx={itemSx ?? horizontalFormControlGridLeftStyle} data-sentry-element="Grid" data-sentry-source-file="CheckboxFormControl.tsx">
					<StyledFormLabel htmlFor={name} aria-describedby={name} hidden={isHidden} error={formState?.errors[name] !== undefined} sx={textSx ?? {
            ...requiredLabelStyle,
            textAlign: textAlign ?? "right"
          }} data-sentry-element="StyledFormLabel" data-sentry-source-file="CheckboxFormControl.tsx">
						{label}
						{isRequired && <Typography style={{
              color: "red",
              fontSize: "18px"
            }}>
								*
							</Typography>}
					</StyledFormLabel>
				</Grid>
				<Grid item xs={xsInput ?? (xsLabel === 12 ? 12 : 12 - xsLabel)} sx={itemSx ?? horizontalFormControlGridRightStyle} data-sentry-element="Grid" data-sentry-source-file="CheckboxFormControl.tsx">
					<FormControlLabel control={<Checkbox id={name} name={name} checked={value ?? defaultValue ?? false} onChange={e => {
            onChange(e.target.checked);
          }} hidden={isHidden} disabled={isDisabled ?? false} size="medium" value={label} sx={{
            width: "30%"
          }} />} label={isShowCheckboxLabel && label} data-sentry-element="FormControlLabel" data-sentry-source-file="CheckboxFormControl.tsx" />
				</Grid>
				{formState && <FieldError formState={formState} name={name} textAlign={textErrorAlign} />}
			</Grid>
		</FormControl>
	</Grid>;
}
export default memo(CheckboxFormControl);