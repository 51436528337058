import { SERVER_DATE_FORMAT } from "@constants/common";
import { timeDifferenceString } from "@utils/utils-client";
import moment from "moment";


export interface INotificationItem {
    id: number;
    type: NotificationType;
    content: string;
    contentHtml: string;
    data: any;
    isRead: boolean;
    relatedId: any;
    createDate: Date | null;
    isNew: boolean;
    timeIndicator: string;
};

export class NotificationItem implements INotificationItem {
    id: number = 0;
    type: NotificationType = NotificationType.Common;
    content: string = "";
    contentHtml: string = "";
    data: any;
    isRead: boolean = false;
    relatedId: any;
    createDate: Date | null = null;
    isNew: boolean = false;
    timeIndicator: string = "";

    constructor(initialFields: any = undefined) {
        if (initialFields) {
            Object.assign(this, initialFields);

            const serverDate = moment.utc(this.createDate, SERVER_DATE_FORMAT);
            this.timeIndicator = timeDifferenceString(serverDate, moment());

            this.isNew = this.timeIndicator?.endsWith('m') || this.timeIndicator?.endsWith('hr') || this.timeIndicator?.endsWith('now');

            if (this.data) {
                this.data = JSON.parse(this.data);
            }
        }
    }
};

export enum NotificationType {
    Common = 0,
    RequestApproval = 1,
    BulkExcludeAccount = 2,
};

export enum NotificationActionType {

    MarkAsRead = 1,
    Approve = 2,
    Open = 3,
    Download = 4,
};


