import { FormState } from ".";

interface IEscheatExclusionReason {
    id: number;
    exclusionReasonName: string;
    exclusionReasonDescription: string;
    active: boolean;
}

interface IChangeStatusOfFilingsAndNotices {
    accountReferenceIDs: string[];
    escheatStatusId: EscheatStatus;
    exclusionReasonId?: number;
    responseReceivedDate?: string;
};

interface IFilingsAndNotices {
    clientCode: string;
    escheatableState: string;
    escheatableStatus: string;
    escheatableAmount: number;
    escheatableDate: Date | null;
    escheatableDueDate: Date | null;
    responseDueDate: Date | null;
    accountReferenceID: string;
    purchaserName: string;
    purchaserAddress: string;
    recipientName: string;
    recipientAddress: string;
    statusId: EscheatStatus;
    originalEscheatableDueDate: Date | null;
    returnedToSenderMailReceived: string;
    noticeResponseReceivedDate: Date | null;
    accountNoticePdfDraftLink: string;
    accountNoticeAggregatePdfDraftLink: string;
}

interface IVerifyAccountItem {
    clientCode: string | null;
    escheatableDueDate: string | null;
    accountUUID: string;
}

enum EscheatStatus {
    EscheatReady = 1,
    NoticesDraft = 2,
    NoticesDraftReady = 3,
    NoticesReady = 4,
    NoticesRejected = 5,
    NoticesSent = 6,
    NoticesResponseReceived = 7,
    FilingNoResponse = 8,
    FilingDraft = 9,
    FilingReadyForUpload = 10,
    FilingReadySubmit = 11,
    FilingRemitted = 12,
    FilingComplete = 13,
    PublicationDraftReady = 14,
}

enum JobRunStatus {
    Unknown = 0,
    NotStarted = 1,
    InProcessing = 2,
    Success = 3,
    Failed = 4,
    HasError = 5,
}

enum EscheatStatusName {
    EscheatReady = 'Escheat - Ready',
    NoticesDraft = 'Notices - Draft',
    NoticesDraftReady = 'Notices - Draft Ready',
    NoticesReady = 'Notices - Ready',
    NoticesRejected = 'Notices - Rejected',
    NoticesSent = 'Notices - Sent',
    NoticesResponseReceived = 'Notices - Response Received',
    FilingNoResponse = 'Filing - No Response',
    FilingDraft = 'Filing - Draft',
    FilingReadyForUpload = 'Filing - Ready For Upload',
    FilingReadySubmit = 'Filing - Ready To Submit',
    FilingRemitted = 'Filing - Remitted',
    FilingComplete = 'Filing - Complete',
    PublicationDraftReady = 'Publication - Draft Ready',
}

enum EscheatFilterGroup {
    Draft = 'Draft',
    Send = 'Sent',
    Exclusion = 'Exclusion',
    Filed = 'Filed',
    ResponseReceived = 'ResponseReceived'
};

export const getEscheatStatusesByFilterGroup = (group: EscheatFilterGroup) => {
    switch (group) {
        case EscheatFilterGroup.Draft:
            return [EscheatStatus.EscheatReady];
        case EscheatFilterGroup.Send:
            return [
                EscheatStatus.NoticesDraft,
                EscheatStatus.NoticesDraftReady,
                EscheatStatus.NoticesReady,
                EscheatStatus.NoticesSent
            ];
        case EscheatFilterGroup.Exclusion:
            return [EscheatStatus.NoticesRejected];
        case EscheatFilterGroup.Filed:
            return [
                EscheatStatus.FilingComplete,
                EscheatStatus.FilingDraft,
                EscheatStatus.FilingNoResponse,
                EscheatStatus.FilingReadyForUpload,
                EscheatStatus.FilingReadySubmit,
                EscheatStatus.FilingRemitted,
                EscheatStatus.PublicationDraftReady
            ];
        case EscheatFilterGroup.ResponseReceived:
            return [EscheatStatus.NoticesResponseReceived];
    }
    return [];
}

export const CAN_APPROVE_STATUSES = [
    EscheatStatus.NoticesDraftReady,
];

export const CAN_EXCLUDE_STATUSES = [
    EscheatStatus.EscheatReady,
    EscheatStatus.NoticesDraft,
    EscheatStatus.NoticesDraftReady,
    EscheatStatus.NoticesReady,
    EscheatStatus.NoticesResponseReceived,
    EscheatStatus.NoticesSent,
    EscheatStatus.FilingDraft,
    EscheatStatus.FilingNoResponse,
    EscheatStatus.PublicationDraftReady,
];

type VerifiedAccountState = FormState & {
    clientCode?: string;
    accountReferenceID?: string;
    totalRecords?: number;
    escheatableState?: string;
    escheatableAmount?: number;
    escheatableDueDate?: Date | null;
};

enum ExclusionReasonIds {
    StaleData = 1,
    OtherContract = 2,
    AdditionalTransactions = 3,
    Fraud = 4,
    AnonymousMaskedPII = 5,
    AccountVerified = 6,
    ResponseReceived = 7,
}

export { EscheatStatus, EscheatStatusName, EscheatFilterGroup, ExclusionReasonIds, JobRunStatus }
export type { IEscheatExclusionReason, IChangeStatusOfFilingsAndNotices, IFilingsAndNotices, IVerifyAccountItem, VerifiedAccountState }